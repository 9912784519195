import { Component, Vue } from "vue-property-decorator";
import { STRAPI_BASE_API_URL } from "@/utils/Constants";
import RequestFilter, { filterToQuery } from "@/models/strapi/RequestFilter";
import { News } from "@/models";
import { httpStore } from "@/store/typed";

export enum StrapiNewsTags {
  NewsIndex = "NewsIndex",
  NewsShow = "NewsShow",
}

@Component
export default class StrapiNewsMixin extends Vue {
  getSingleNews(id: any): Promise<News> {
    const url = `${STRAPI_BASE_API_URL}/news/${id}`;
    return httpStore.request({
      tag: StrapiNewsTags.NewsShow,
      url: url,
      method: "GET",
    }) as Promise<News>;
  }

  getNews(filter: RequestFilter): Promise<News[]> {
    const url = `${STRAPI_BASE_API_URL}/news?${filterToQuery(filter)}`;
    return httpStore.request({
      tag: StrapiNewsTags.NewsIndex,
      url: url,
      method: "GET",
    }) as Promise<News[]>;
  }
}
