
import { Mixins, Component } from "vue-property-decorator";
import PageSection from "@/components/Common/PageSection.vue";
import NewsMixin from "@/mixins/http/strapi/NewsMixin";
import NewsCard from "@/components/News/Card.vue";
import News from "@/models/strapi/News";
import { RegisterHttp } from "@/utils/Decorators";
import RequestData from "@/store/types/RequestData";
import RequestFilter from "@/models/strapi/RequestFilter";
import { safeAsync } from "@/utils/AsyncUtil";
import { zoneStore } from "@/store/typed";
const tag = "get_news";

const MAX_ITEMS = 48;

@Component({ components: { PageSection, NewsCard } })
export default class NewsList extends Mixins(NewsMixin) {
  @RegisterHttp(tag) readonly indexRequest!: RequestData;

  private items: News[] = [];
  private stopLoading = false;
  private error = false;

  private filter: RequestFilter = {
    start: 0,
    limit: 12,
  };

  get maxItems() {
    return zoneStore.zoneParameters?.max_news;
  }

  loadMore() {
    if (
      this.loading ||
      this.items.length === this.maxItems ||
      this.stopLoading
    ) {
      return;
    }
    this.filter.start += this.filter.limit;
    this.load();
  }

  mounted() {
    window.scroll({ top: 0, behavior: "smooth" });
    this.load();
  }

  get loading() {
    return this.indexRequest?.loading;
  }

  async load() {
    const [data, errors] = await safeAsync(this.getNews(this.filter));

    if (!errors) {
      this.error = false;
      if (!data.length) {
        this.stopLoading = true;
        this.filter.start -= this.filter.limit;
        return;
      }
      if (this.filter.start === 0) {
        this.items = data;
      } else {
        this.items.push(...data);
      }
    } else {
      this.error = true;
    }
  }
}
