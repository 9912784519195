
import { Component, Mixins, Watch } from "vue-property-decorator";
import { zoneStore } from "@/store/typed";
import NewsMixin from "@/mixins/http/strapi/NewsMixin";
import { urlFromMedia } from "@/utils/UrlHelper";
import { safeAsync } from "@/utils/AsyncUtil";
import marked from "marked";
import { RegisterHttp } from "@/utils/Decorators";
import RequestData from "@/store/types/RequestData";
import { News } from "../../models";
import moment from "moment";
import { NoCache } from "@/utils/Decorators";

const tag = "get_single_news";
@Component({})
export default class NewsShow extends Mixins(NewsMixin) {
  @RegisterHttp(tag) readonly request!: RequestData;

  private item: News | null = null;

  private error = false;

  mounted() {
    this.load();
  }

  get date() {
    if (!this.item) {
      return "";
    }
    return moment(this.item.date).format("DD MMM YYYY");
  }

  get id(): string | number {
    return this.$route.params.id;
  }

  @Watch("id")
  onIdChanhed(value: string) {
    this.load();
  }

  get sanitizedMarkdown(): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(this.htmlContent, "text/html");
    const images = doc.getElementsByTagName("img");
    for (var i = 0; i < images.length; i++) {
      images[i].style.width = "100%";
    }
    return doc.body.innerHTML;
  }

  goToNext() {
    this.$router.push({ name: "news_show", params: { id: this.nextNews.id } });
  }

  goToPrevious() {
    this.$router.push({
      name: "news_show",
      params: { id: this.previousNews.id },
    });
  }

  async load() {
    const [data, errors] = await safeAsync(this.getSingleNews(this.id));
    if (!errors) {
      this.error = false;
      this.item = data;
      window.scroll({ top: 0, behavior: "smooth" });
    } else {
      this.error = true;
    }
  }

  @NoCache
  get nextNews() {
    const index = this.news.findIndex(
      (o: News) => o.id.toString() === this.id.toString()
    );
    return this.news[index + 1];
  }

  @NoCache
  get previousNews() {
    const index = this.news.findIndex(
      (o: News) => o.id.toString() === this.id.toString()
    );
    return this.news[index - 1];
  }

  get htmlContent() {
    return this.item?.content ? marked(this.item.content) : "";
  }

  get coverImageUrl() {
    return this.item ? urlFromMedia(this.item.cover, "large") : "";
  }

  get news(): any {
    return zoneStore.news;
  }

  get loading() {
    return this.request?.loading;
  }
}
